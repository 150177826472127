
  import { defineComponent, onMounted, ref, computed, watchEffect } from "vue";
  import { hideModal } from "@/core/helpers/dom";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import { Actions } from "@/store/enums/StoreEnums";
  import { useStore } from "vuex";
  import ApiService from "@/core/services/ApiService";
  
  export default defineComponent({
    name: "add-tag-modal",
    components: {},
    props: ['edit', 'item'],
    setup(props) {
      const formRef = ref<null | HTMLFormElement>(null);
      const addLanguageModalRef = ref<null | HTMLElement>(null);
      const store = useStore();
      const loading = ref<boolean>(false);
      const add = ref<boolean>(true)
      const formData = ref({
        name: "",
        subcategory: "",
        _id: null,
      });


      const resetForm = ()=>{
        formData.value.name = "",
        formData.value.subcategory = ""
      }
  
      const rules = ref({
        name: [
          {
            required: true,
            message: "Customer name is required",
            trigger: "change",
          },
        ],
        subcategory: [
          {
            required: true,
            message: "Subcategory is required",
            trigger: "change",
          },
        ],
      });



      watchEffect(()=>{
        add.value = !props.edit
        if(!add.value){
          ApiService.get('tag/getone/'+ props.item).then((res)=>{
              formData.value.name = res.data.name;
              formData.value._id = res.data._id;
              formData.value.subcategory = res.data.subcategory._id;   
          })
        }else{
          resetForm()
        }
      })

  
      const submit = () => {
        if (!formRef.value) {
          return;
        }
  
        formRef.value.validate((valid) => {
          if (valid) {
            loading.value = true;
            if(add.value){
              formData.value.name = '#'+formData.value.name;
              store.dispatch(Actions.ADD_TAG, formData);
            }else{
              store.dispatch(Actions.EDIT_TAG, formData);
            }
            
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Form has been successfully submitted!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                add.value = true
                resetForm();
                hideModal(addLanguageModalRef.value);
              });
            }, 2);
          } else {
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        });
      };
      const subcategories = computed(()=>{
        return store.getters.alSubategories
      })
  
      onMounted(async()=>{
        await store.dispatch(Actions.ALL_SUBCATEGORIES, {params:{limit: 0}});
      })
      return {
        formData,
        rules,
        submit,
        formRef,
        loading,
        addLanguageModalRef, add, subcategories
      };
    },
  });
  